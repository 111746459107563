import { v4 as uuidv4 } from 'uuid';

window.gon = {
    config: {
        // Auth0 configuration values
        AUTH0_API_CLIENT_ID: 'lAGrV8zoYlhai0LlodKMjSiLCZY3ox6c',
        AUTH0_API_CLIENT_SECRET: '<%= decrypt "pICqneBKWW3M16/mMeccUJ5YNwCmeZvmiIpHA0xscnp7sziXTMy1vVFvWfSdkVgxHhTTtUmkwuD0I5mC5ZISihkl8bw2d0r+E/l+iJMpSE45VMaGzzLKIg==" %>',
        AUTH0_DOMAIN_URL: 'https://prd-patientfusion.auth0.com',
        PATIENT_PORTAL_AUTH0_CLIENT_ID: '5s571lL06q9y3LxN7EaygckjgPHR20nU',
        PHR_AUTH_TOKEN_KEY: 'phr_auth_token',

        // UI configuration values
        AGREEMENT_ENDPOINT_URL: 'https://agreement-endpoint.patientfusion.com/api/v1',
        BILLING_ENDPOINT_URL: 'https://billing-endpoint.patientfusion.com/api/v1',
        CLINICAL_ENDPOINT_URL: 'https://clinical-endpoint.patientfusion.com/api/v1',
        EHR_INTAKE_URL: 'https://intake.practicefusion.com',
        EHR_TOKEN_ENDPOINT_URL: 'https://static.practicefusion.com/IntakeEndpoint/api/v1',
        INTAKE_ENDPOINT_URL: 'https://intake-endpoint.patientfusion.com/api/v2',
        INTAKE_LOGIN_URL: 'https://intake.patientfusion.com',
        PHR_HOME_URL: 'https://my.patientfusion.com',
        PHR_PATIENT_ENDPOINT_URL: 'https://phr-patient-endpoint.patientfusion.com/api/v1',
        PHR_TOKEN_ENDPOINT_URL: 'https://phr-authn-endpoint.patientfusion.com/api/v3',
        PF_LOGIN_URL: 'https://login.patientfusion.com'
    },
    env: 'production',
    sessionKey: uuidv4(),
};